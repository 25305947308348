import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

import "./Footer.scss";

const Footer = () => {

  return (
    <footer className="py-5">
      <div className="container">
        <div className="row justify-content-md-between">
          <div className="col-12 col-md-6">
            <div className="row">
            <h2 className="h5 mb-3">Cloud Transport Manager</h2>
            <p><FormattedMessage id="footer.subtitle" /></p>
          </div>
          </div>
          <div className="col-12 col-md-6">
            
            <div className="row justify-content-md-end">
              <div className="mb-3" style={{paddingTop: "36px"}}>
                ©{" "}
                <a href="https://coalsoft.cz/" target="_blank" style={{marginRight:"2rem"}}>
                  coalsoft s.r.o.
                </a>
              </div>
              <div className="mb-3" style={{paddingTop: "36px"}}>
                ©{" "}
                <a href="https://or.justice.cz/ias/ui/rejstrik-$firma?ico=09543031" target="_blank">
                  ctmsoft s.r.o.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
