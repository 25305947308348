import React from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";

const languageName = {
  en: "English",
  de: "Deutsch",
  cs: "Česky",
};

const Language = () => {

  const createDropdownItems = (languages, currentLocale) => {
    const tmp = [];
    languages.map((language) => {
      if (language !== currentLocale) {
        tmp.push(
          <a key={language} onClick={() => changeLocale(language)} className="dropdown-item">
            {languageName[language]}
          </a>
        );
      }
    });
    return tmp;
  };

  return (
    <div className="dropdown">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language) =>
            currentLocale === language ? (
              <a
                className="dropdown-toggle pr-2"
                id="dropdownLangButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {languageName[currentLocale]}
              </a>
            ) : (
              <div className="dropdown-menu dropdown-menu-language" aria-labelledby="dropdownLangLink">
                {createDropdownItems(languages, currentLocale)}
              </div>
            )
          )
        }
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
