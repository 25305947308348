import React, { useEffect, useState } from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import PropTypes from "prop-types";
import Language from "./language";
import Scrollspy from "react-scrollspy";
import Scroll from "../Scroll";
import { useLocation } from "@reach/router";

import "./Header.scss";

const Header = (props) => {
  const location = useLocation();

  const createNavLink = (identifier, offset = -100) => {
    if (location.pathname.indexOf('wishlist') !== -1) {
      return (<Link className="nav-link" to={`/#${identifier}`}><FormattedMessage id={`nav.${identifier}`}/></Link>)
    } else {
      return (
        <Scroll type="id" element={identifier} offset={offset}>
          <a className="nav-link" href={`#${identifier}`}>
            <FormattedMessage id={`nav.${identifier}`} />
            {/* {identifier} */}
          </a>
        </Scroll>
      );
    }
  };

  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  

  return (
    <nav id="mainNav" className={`navbar navbar-expand-xl fixed-top ${scroll ? "nav-primary" : "nav-secondary"}`}>
      <div className="container">
        <Link to="/" className="navbar-brand">
          {/* {siteTitle} */}
          Cloud Transport Manager
        </Link>

        <div className="navbar-toggler-div">
          <ul className="navbar-nav pr-4 mr-4 divider-right d-none d-sm-flex">
            <li className="nav-item">
              <a className="nav-link" href="#">
                <FormattedMessage id="nav.login" />
              </a>
            </li>
          </ul>
          <button
            className="navbar-toggler "
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarToggler">
          <Scrollspy
            className="navbar-nav mr-auto"
            items={["home", "product", "pricing", "contact"]}
            currentClassName="active"
            offset={-100}
          >
            <li className="nav-item">{createNavLink("home")}</li>
            <li className="nav-item">{createNavLink("product")}</li>
            <li className="nav-item">{createNavLink("pricing")}</li>
            <li className="nav-item">{createNavLink("contact")}</li>
            <li className="nav-item"><Link className="nav-link" to="/wishlist/"><FormattedMessage id="nav.wishlist"/></Link></li>
          </Scrollspy>

          <div className="d-flex align-items-xl-center justify-content-start flex-xl-row flex-column">
            <div className="mr-2 py-2">
              <Language />
            </div>

            {/*<Link to="/login" className="divider-left pl-xl-3 py-2">*/}
            <a className="divider-left pl-xl-3 py-2" href="https://www.app.cloudtransportmanager.com/#/Login">
              <FormattedMessage id="nav.navToApp" />
            </a>
            {/*</Link>*/}
          </div>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
